import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Card, CardImgOverlay } from "reactstrap"
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import I18n from '../components/i18n'
import Layout from "../components/layout"
import ContactModal from "../components/contactModal"

const TourPost = ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout location={location}>
      <Helmet
        title={`Fremdenführer ${post.frontmatter.title}`}
        meta={[
          { name: 'description', content: 'Die schönsten Touren durch das Salzkammergut, Hallstatt, Bad Ischl, Bad Aussee, Salzburg, Linz und viele weitere.' },
          { name: 'keywords', content: 'Touren, Guide, Salzkammergut, Hallstatt, Bad Ausee, Salzburg, Linz, Austria Guides' },
        ]} />
      <Card className="bg-light rounded-0">
        <Img fluid={post.frontmatter.cover.childImageSharp.fluid} className="card-img rounded-0" />
        <CardImgOverlay className="pb-0 pt-lg-5 bg-light-alpha" style={{ top: 'auto' }}>
          <Container>
            <Row>
              <Col lg="6" className="bg-white pt-3 pt-lg-5">
                <h6 className="text-uppercase mx-lg-5"><I18n value="tours.heading" /></h6>
                <h1 className="text-uppercase mx-lg-5 h2 mb-0">{post.frontmatter.title}</h1>
              </Col>
            </Row>
          </Container>
        </CardImgOverlay>
      </Card>

      <Container className="py-0 py-lg-4">
        <Row className="my-5">
          <Col xl="8" className="tours-post text-justify">
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </Col>
          <Col xl="4" className="text-xl-right mt-5 mt-xl-0">
            <h6 className="text-uppercase"><I18n value="tours.prices" /></h6>
            <h5 className="text-uppercase"><I18n value="tours.offer" /></h5>
            <table className="table table-light">
              <caption className="small text-right">
                <span class="d-block"><I18n value="tours.offer.foreignfee" /></span>
                <span class="d-block"><I18n value="tours.offer.surcharge" /></span>
                <span class="d-block"><I18n value="tours.offer.groupsize" /></span>
              </caption>
              <tbody>
                <tr>
                  <td><I18n value="tours.offer.city" /> <small className="d-block"><I18n value="tours.offer.city.time" /></small></td>
                  <td className="align-middle text-nowrap"><small className="d-block"><I18n value="tours.offer.pricing.start" /></small> € 125,00</td>
                </tr>
                <tr>
                  <td><I18n value="tours.offer.halfday" /> <small className="d-block"><I18n value="tours.offer.halfday.time" /></small></td>
                  <td className="align-middle text-nowrap"><small className="d-block"><I18n value="tours.offer.pricing.start" /></small> € 195,00</td>
                </tr>
                <tr>
                  <td><I18n value="tours.offer.fullday" /> <small className="d-block"><I18n value="tours.offer.fullday.time" /></small></td>
                  <td className="align-middle text-nowrap"><small className="d-block"><I18n value="tours.offer.pricing.start" /></small> € 295,00</td>
                </tr>
                <tr>
                  <td><I18n value="tours.offer.themenfuehrungen" /></td>
                  <td className="align-middle text-nowrap"> € 130,00</td>
                </tr>
                <tr>
                  <td><I18n value="tours.offer.additional" /></td>
                  <td className="align-middle text-nowrap">€ 30,00</td>
                </tr>
              </tbody>
            </table>

            <ContactModal buttonLabel={<I18n value="tours.offer.calltoaction" />} formName={post.frontmatter.title} />

          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default TourPost

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        cover {
          childImageSharp {
            id
            fluid(maxWidth: 1200, maxHeight: 450, quality: 85) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`