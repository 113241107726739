import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, Container, Row, Col, FormGroup, Input, FormText } from 'reactstrap'
import { IoIosCheckmarkCircleOutline, IoIosCall, IoIosMail } from 'react-icons/io'
import NetlifyForm from 'react-netlify-form'
import I18n from './i18n'

class ContactModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      name: '',
      email: '',
      message: '',
      emailValid: false,
      nameValid: false,
      messageValid: false,
      formValid: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  validateField(fieldName, value) {
    let emailValid = this.state.emailValid
    let nameValid = this.state.nameValid
    let messageValid = this.state.messageValid

    switch (fieldName) {
      case 'email':
        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        emailValid = re.test(value)
        break;
      case 'name':
        nameValid = value.length >= 1
        break;
      case 'message':
        messageValid = value.length >= 1
        break;
      default:
        break
    }

    this.setState({
      emailValid: emailValid,
      nameValid: nameValid,
      messageValid: messageValid
    }, this.validateForm)
  }

  validateForm() {
    this.setState({ formValid: this.state.emailValid && this.state.nameValid && this.state.messageValid})
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState(
      {[name]: value},
      () => {this.validateField(name, value)}
    )
  }

  onValidateForm = (formData) => {
    for (let value of formData.entries()) {
      this.validateField(value[0], value[1])
    }
    this.validateForm()
    return !this.state.formValid
  }

  render() {
    return (
      <div>
        <Button color="danger" onClick={this.toggle}>
          <IoIosCheckmarkCircleOutline className="mr-3" style={{ fontSize: '1.8rem' }} />
          <span>{this.props.buttonLabel}</span>
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="lg" centered>
          <ModalHeader toggle={this.toggle}>{this.props.buttonLabel}</ModalHeader>
          <ModalBody>
            <Container fluid>
              <Row>
                <Col lg="6">
                  <NetlifyForm name={this.props.formName} validate={this.onValidateForm} honeypotName="__nfb">
                  {({ loading, error, success }) => (
                    <div>
                    {loading &&
                      <div><I18n value="modal.loading" /></div>
                    }
                    {error &&
                      <div><I18n value="modal.error" /></div>
                    }
                    {success &&
                      <div><I18n value="modal.success" /></div>
                    }
                    {!loading && !success &&
                      <div>
                        <p><I18n value="modal.left.text" /></p>
                        <FormGroup>
                          <Input type="text" name="name" placeholder="Name *" value={this.state.name} onChange={this.handleChange} required />
                        </FormGroup>
                        <FormGroup>
                          <Input type="email" name="email" placeholder="Email *" value={this.state.email} onChange={this.handleChange} required />
                        </FormGroup>
                        <FormGroup>
                          <Input type="textarea" name="message" placeholder="Anfrage *" value={this.state.message} onChange={this.handleChange} required />
                        </FormGroup>
                        <FormText color="muted">
                          <small><I18n value="modal.mandatory" /></small>
                        </FormText>
              
                        <Button color="danger" type="submit" className="mt-3" disabled={!this.state.formValid}><I18n value="modal.submit" /></Button>
                      </div>
                    }
                  </div>
                  )}
                  </NetlifyForm>
                </Col>
                <Col lg="6" className="mt-4 mt-lg-0">
                  <p><I18n value="modal.right.text" /></p>
                  <p><IoIosCall className="mr-2 h4 mb-0" /> <a href={`tel:+43 664 20 650 83`}>+43 664 20 650 83</a></p>
                  <p><IoIosMail className="mr-2 h4 mb-0" /> <a href={`mailto:campanile@hallstatt-guides.at`}>campanile@hallstatt-guides.at</a></p>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>

        {/* A little help for the Netlify bots to find the form */}
        <form name={this.props.formName}  data-netlify="true" data-netlify-honeypot="__nfb" hidden>
          <input type="text" name="name" />
          <input type="email" name="email" />
          <textarea name="message"></textarea>
        </form>
      </div>
    );
  }
}

export default ContactModal;